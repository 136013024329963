import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg";
import { Form } from "../Form/Form";
import { BrowserRouter } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export function Contact() {
  return (
    <Container id="contato">
      <header>
        <h2>Contact me</h2>
        <p>
          if you've seen my potential, want to hire me or interested in learning
          programming.
          <br /> Don't hesitate to send a message.
        </p>
      </header>
      <div className="contacts">
        <div>
          <img src={emailIcon} alt="Email" />
          <a href="mailto:cjfrancisf@gmail.com">send email</a>
        </div>
        <div>
          <img src={phoneIcon} alt="Email" />
          <a href="tel:+2348156165707">phone call</a>
        </div>
        <div>
          <WhatsAppIcon style={{ position: "relative", top: 2 , fontSize: 40, color: 'black'}} />
          <a href="tel:+2348077417209">whatsapp</a>
        </div>
      </div>
      {/* <Form></Form> */}
    </Container>
  );
}
