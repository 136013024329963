import { Container } from './styles'

import reactIcon from '../../assets/react-icon.svg'
import linkedinIcon from '../../assets/linkedin.png'
import githubIcon from '../../assets/github.png'
import instagramIcon from '../../assets/instagram.png'
import discordIcon from '../../assets/discord.png'
import logo from '../../assets/logo.png'

export function Footer() {
  return (
    <Container className="footer">
      <a href="/" className="logo">
        <span><img alt='logo' src={logo} style={{ width: 30, height: 30, position: 'relative',top: 5 }} /></span>
        <span>Techkrab</span>
      </a>
      <div>
        <p>
          This site was made with <img src={reactIcon} alt="React" /> by
          <span>❤️Techkrab</span>
        </p>
      </div>

      <div className="social-media">
        <a
          href="https://www.linkedin.com/in/justice-francis-25abb61ab"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedinIcon} alt="Linkedin" />
        </a>

        <a
          href="https://github.com/JusticeFrancis"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>

        <a
          href="https://www.instagram.com/techkrab/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" />
        </a>

      </div>
    </Container>
  )
}
