import { Container } from "./styles";
import githubIcon from "../../assets/github-icon.svg";
import externalLinkIcon from "../../assets/external-link-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";
import { useState } from "react";

interface incomming {
  id: number;
  color: string;
}

export function Portfolio() {
  const location: string = window.location.href;
  const [selected, setSelected] = useState<incomming>({
    id: 0,
    color: "",
  });
  window.addEventListener("load", (event) => {
    const redir = String(
      new URLSearchParams(window.location.search).get("redir")
    );
    const elem = document.getElementById(redir)?.scrollIntoView();
    switch (redir) {
      case "liuyangtong":
        setSelected({
          id: 1,
          color: "1px solid green",
        });

        break;
      case "ethswap":
        setSelected({
          id: 2,
          color: "1px solid green",
        });

        break;
      case "tronisys":
        setSelected({
          id: 3,
          color: "1px solid green",
        });

        break;

      case "kogan":
        setSelected({
          id: 4,
          color: "1px solid green",
        });

        break;

      case "github-searcher":
        setSelected({
          id: 5,
          color: "1px solid green",
        });

        break;

      case "music-library":
        setSelected({
          id: 6,
          color: "1px solid green",
        });

        break;

      case "bochnews":
        setSelected({
          id: 7,
          color: "1px solid green",
        });

        break;

      case "chat":
        setSelected({
          id: 8,
          color: "1px solid green",
        });

        break;
      case "techkrabpay":
        setSelected({
          id: 9,
          color: "1px solid green",
        });

        break;
      case "ecommerce":
        setSelected({
          id: 10,
          color: "1px solid green",
        });

        break;
      case "culturedturtles":
        setSelected({
          id: 11,
          color: "1px solid green",
        });

        break;

        case "bnbbuyer":
          setSelected({
            id: 10,
            color: "1px solid green",
          });
  
          break;

      default:
        break;
    }

    /* if (location === "http://192.168.43.36:3000/#luiyangtong") {
      setSelected("1px solid green");
    } */
  });
  console.log(location);
  return (
    <Container id="portfolio">
      <h2>My portfolio / Projects(12)</h2>

      <div className="projects">
      <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 10
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="bnbbuyer"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links" id="12">
                <a
                  href="https://app.bnbbuyer.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Bnbbuyer</h3>
              <p>
                {" "}
                BnB Buyer is a resource to see what's on the market, which markets are trending , potential financial scenarios,etc.Its a key STR investment research toolkit.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Node</li>
                <li>Stripe</li>
                <li>React</li>
                <li>MongoDb</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 10
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="ecommerce"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links" id="1">
                <a
                  href="https://shopify-client-app.vercel.app/nikegod"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Personal Ecommerce Project</h3>
              <p>
                {" "}
                A Person Ecommerce project where users can create stores you see
                when you click the "externalLinkIcon" and get specialized URL's
                which they can share to their customers. It also features cart
                and catalogue as well. The payment gateway can be PAYSTACK or
                STRIPE
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Node</li>
                <li>Stripe</li>
                <li>React</li>
                <li>MongoDb</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 1
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="liuyangtong"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links" id="1">
                <a
                  href="https://staging.liuyangtong.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Liuyangtong</h3>
              <p>
                {" "}
                A web application for online tutoring with videos, chatrooms and
                certificates. I was hired to build both frontend and backend.
                Project is still in progress.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Node</li>
                <li>D.O.</li>
                <li>React</li>
                <li>MongoDb</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 11
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="culturedturtles"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links" id="1">
                <a
                  href="https://culturedturtles.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Cultured Turtles</h3>
              <p>
                {" "}
                An NFT marketplace website made for CULTURED TURTLES LLC. it
                features connection to wallets like metamask and bitski,
                whitelistings and integration of Open Sea.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Mailgun</li>
                <li>Node js</li>
                <li>Firebase</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 2
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="ethswap"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://github.com/JusticeFrancis/ethdappswap"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={githubIcon} alt="GitHub" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Ethswap DApp</h3>
              <p>
                Ethswap is a decentralised application that is built using
                JavaScript and solidity smart contracts. The smart contract
                includes token.sol which uses the ERC-20 guidelines to create a
                new token "DApp token", this token can be exchanged with
                ethereum. completed April 2022.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Solidity</li>
                <li>ganache</li>
                <li>metamask</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 3
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="tronisys"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://tronisys.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Tronisys</h3>
              <p>
                Tronisys is an Isreali company that help suppliers and
                contractors find government tendering opportunities in Isreal. I
                was hired to design the frontend from a figma template and
                integrate APIs.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>API</li>
                <li>Figma</li>
                <li>Material UI</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 4
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="kogan"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://kogan.com/au/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Kogan</h3>
              <p>
                An australian ecommerce store that deals on home, clothing and
                school accessories like furnitures, gadgets and home appliances.
                I was hired for frontend and backend development. Project was
                completed December 2021.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>PHP</li>
                <li>HTML/CSS</li>
                <li>Node js</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 5
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="github-searcher"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://github.com/JusticeFrancis/github-search"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={githubIcon} alt="GitHub" />
                </a>
                <a
                  href="https://github-search-blond.vercel.app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Github Searcher</h3>
              <p>
                A nicer version to view your GitHub profile, includes data
                visualization, repositories, sharing and more.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Typescript</li>
                <li>Styled Components</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 6
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="music-library"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b "
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://github.com/justicefrancis/music-app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={githubIcon} alt="visit site" />
                </a>
                <a
                  href="https://justicefrancis.github.io/music-app/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Music Library</h3>
              <p>
                A pure html and css musical application interface similar to
                spotify. completed May 2021.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Html</li>
                <li>Css</li>
                <li>Vanilla JavaSript</li>
                <li>Git</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 7
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="bochnews"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://bochnewsng.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Bochnews</h3>
              <p>
                An online news and information company. The website shows daily
                news relating to Nigeria and its neighbours. I was hired to
                design frontend. The project was completed last year.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Wordpress</li>
                <li>Html</li>
                <li>Css</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 8
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="chat"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://github.com/justicefrancis/react-chat-app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={githubIcon} alt="visit site" />
                </a>
                <a
                  href="https://react-chat-app-tan.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>React chat app</h3>
              <p>
                The chat application was created using react and node js with
                socket.io. you must have to register/login and would require
                customer service online to chat with. check git repo by clicking
                icon above to see documentation.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Node js</li>
                <li>Socket.io</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 8
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="fuzu"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://fuzu.com" target="_blank" rel="noreferrer">
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Fuzu</h3>
              <p>
                Fuzu is one of Africa's leading Job board and agency, that
                bridges the gap between Employers and skilled workers in
                Nigeria, Uganda and Kenya
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Node js</li>
                <li>D.O.</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div
            className="project"
            style={
              selected.id === 9
                ? { border: selected.color }
                : { border: "none" }
            }
          >
            <header>
              <svg
                id="techkrabpay"
                width="50"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#23ce6b"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a
                  href="https://github.com/justicefrancis/techkrab.pay"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={githubIcon} alt="visit site" />
                </a>
                <a
                  href="https://techkrab-pay.vercel.app"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={externalLinkIcon} alt="visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Techkrab pay</h3>
              <p>
                Techkrab pay is a UI for a finance application, that could
                involve funds transfers, payment gateways and possibly crypto
                payments
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Material UI</li>
                <li>Dribble</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  );
}
